.App {
    margin: 2px;

    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

table.timecard {
	margin-left: 2px;
    align-self: start;
	width: 100%;
	/* border-collapse: collapse; */
	border: 1px solid #0b1d8586; /*for older IE*/
	border-style: solid;
}

table.timecard caption {
	background-color: #274A7E;
	color: #fff;
	font-size: x-large;
	font-weight: bold;
	letter-spacing: .3em;
}

table.timecard thead th {
	padding: 5px;
	background-color: #274A7E;
	font-size: medium;
    font-weight: bold;
    color: #fff;
}

table.timecard thead th#thDay {
	width: 40%;	
}

table.timecard thead th#thRegular, table.timecard thead th#thOvertime, table.timecard thead th#thTotal {
	width: auto;
}

table.timecard th, table.timecard td {
	padding: 3px;
	border-width: 1px;
	border-style: solid;
	border-color: #274A7E #ccc;
}

table.timecard td {
	text-align: left;
}

table.timecard tbody {
	text-align: left;
	font-weight: normal;
  padding: 5px;
}

table.timecard th {
	text-align: center;
	font-weight: normal;
}

table.timecard tfoot {
	font-weight: bold;
	font-size: large;
	background-color: #687886;
	color: #fff;
}

table.timecard tr.even {
	background-color: #fde9d9;
}

.routingInput {

    align-content: space-evenly;
}

#originInput {
    padding: 4px;
    padding-top: 20px;
    width: 23vw;
    height: 40px;
    border: rgb(111, 112, 114) 2px solid;
    margin-right: 20px;
    text-align: center;
    border-radius: 4px;
}

#destinationInput {
    padding: 4px;
    padding-top: 20px;
    width: 23vw;
    height: 40px;
    border: rgb(111, 112, 114) 2px solid;
    text-align: center;
    border-radius: 4px;
}

.map-title-box {
    right: 0;
    padding: 2px;
    border-bottom-left-radius: 5px;
    background-color: rgb(1, 9, 53);
    color: white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: center;
    width: 140px;
    z-index: 999;
    position: absolute;
}

.grid-maps {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;
    width: 98%;
    margin-left: 10px;
}

.map {
    border: 1px solid #ccc;
    /* padding: 10px; */
    /* position: relative; */
    height: 75vh;
    width: 100%;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}


.App-header {
    background-color: #4897cc;
    max-height: 8vh;
    display: inline-flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    font-size: calc(8px + 2vmin);
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    /* color: white; */
}

.App-link {
    color: #053555;
}


.map-eta-box {
    background-color: #FF0000;
    border-radius: 2px;
    /* border-right: 10px; */
    width: 250px;
    height: 28px;
    position: absolute;
    z-index: 999;
    padding: 3px;
    bottom: 2px;
    color: white;
    left: 0px;
    text-align: center;
}