body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.logo{
  width: 210px;
  height: 3rem;
}
.navbar {
  /* color: #fff; */
  /* padding: 0; */
  margin: 0;
  height: 6rem;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-brand {
  font-size: 1.5rem;
  color: #fff;
  text-decoration: none;
}

.navbar-nav {
  list-style: none;
  display: flex;
  gap: 1rem;
}

.nav-item .nav-link {
  color: #fff;
  text-decoration: none;
}

.maps-btn{
  margin: 1.5rem;
}

.toolbar {
  display: 'flex';
  background-color: #f4f4f4;
  padding: 0.2rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
  
}


.toolbar-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;
}

.toolbar-button i {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}


.grid{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px, grid;
  margin: 1.5rem;
  position: relative;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

}


.grid-item{
 border: 0.5px solid rgb(41, 41, 41);
 /* position:absolute; */
}

/* .map-title{
  position:absolute;

  font-weight: bold;
  font-size: 30px;
  background-color: rgb(207, 207, 207); 
  z-index: 1;
  
} */


/* .card {
  margin-bottom: 1rem;
} */

.card-header {
  background-color: #f8f9fa;
}

.btn-link {
  text-decoration: none;
  color: #0c60b9;
}

.btn:hover {
  text-decoration: underline;
  text-decoration-color: #0c60b9;
}

.card-body {
  padding: 0.5rem;
  border: none;
}


/* Accordion forms */

.form {
  display: 'flex';
  align-items: 'center';
}

.form-control {
  margin-bottom: 5px;
}

.map-title {
  /* font-weight: bold; */
  background-color: rgba(255, 255, 255, 0.918);
  font-size:  14px/16px Arial, Helvetica, sans-serif;;
  position:'absolute';
   top:'10px'; 
   right:'10px';
  padding: 5px;
  border-radius: 3px;
  z-index: 1000 !important;
}
